import React from 'react';
import { useLocation } from 'react-router-dom'; // for checking the current path
import Back from '../Back';
import puzzle from "./puzzle.jpg";
import minigame from "./minigame.jpg";

function Puzzle() {
    const location = useLocation(); 

    const isPuzzleRoute = location.pathname === '/puzzle';
    const imageSrc = isPuzzleRoute ? puzzle : minigame;
    const text = "Coming Soon";

    return (
        <div style={containerStyle}>
            <Back />
            <div style={imageContainerStyle}>
                <img src={imageSrc} alt={isPuzzleRoute ? "Puzzle" : "Mini Game"} style={imageStyle} />
            </div>
            <div style={textStyle}>{text}</div>
        </div>
    );
}

const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f4f4f4",
};

const imageContainerStyle = {
    marginBottom: "20px",
};

const imageStyle = {
    width: "100%",
    // height: "200px",
    borderRadius: "10px",
    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
};

const textStyle = {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#333",
};

export default Puzzle;
