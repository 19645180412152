import React, {useState,useEffect} from 'react'
import Footer from '../Footer/Footer'
import Back from '../Back'
import airdrop from "./airdrop.png"
import "./airdrop.scss"
import wallet from "./wallet.png"
import arrow from "./arrow.svg"
import WalletConnect from '../WalletConnect/WalletConnect';
import { getPlayerExtraTasks } from '../../http'
import Task from '../Tasks/Task'
import { TonConnectButton, useTonAddress } from '@tonconnect/ui-react';

function Airdrop() {

  const [extraTask, setExtraTask] = useState([]);
  const [show, setShow] = useState(false);
  const tonAddress = useTonAddress();  

  const getPlayerExtraTasksData = async () =>{

    const data = await getPlayerExtraTasks()
    setExtraTask(data.extra_tasks)

 }
 const formatAddress = (address) => {
    if (!address) return "Connect your TON wallet";
    return `${address.slice(0, 6)}....${address.slice(-4)}`;
  };
 useEffect(() => {
  getPlayerExtraTasksData()
}, []);

  return (
    <div>

      {show&&
          <WalletConnect 
              description ={extraTask.description}
              title ={extraTask.title}
              available = {extraTask.available}
              wallet_address = {extraTask.wallet_address}
              onClick = {()=>setShow(false)}
          />
      }

      <Back />
      <div className='airdrop_container'>
        <img src={airdrop} className="image_container" draggable="false" alt="Coin" />
        <div className="title">AirDrop tasks</div>
        <div className='subtitle'>Listing is on its way. Tasks will appear below. Complete them to participate in the AirDrop</div>
        <div className='connect_button'>
          <img src={wallet} className='wallet'/>
          <TonConnectButton
              style={{ background: "none", color: "inherit" }}
          ></TonConnectButton> 
            <div className='max-width'>{formatAddress(tonAddress)}</div>
          <img src={arrow} className='arrow_airdrop'/>
        </div>

        {Object.keys(extraTask).length > 0 && 
            <>
                <div className='daily_text first'>Extra tasks</div>
                    <div className='shop_grid first'>
                        <Task
                            task="Connect your Wallet"
                            onClick={() => setShow(true)}
                            icon ={wallet}
                        />
                </div>
            </>
        }

        
      </div>
      
  

      <Footer/>
    </div>
  )
}

export default Airdrop;
