import React from 'react';
import "./thanks.scss";
import img from "./binance.png";
import { useAppData } from '../../AppContext';
import { useTranslation } from 'react-i18next';
import dollar_coin from "../../pages/dollar_coin.png"
import { sendCoinsAndEnergy } from '../../http';
import { formatNumber } from '../../Utils/formatNumber';
function ThanksNiki({ onClick, coins }) {
    const { t } = useTranslation();
    const { handleEditCoins, setpassiveIncome,unitName ,unit} = useAppData();

    const handleClick = () => {
        
        handleEditCoins(coins);
        sendCoinsAndEnergy(coins,0)
        setpassiveIncome(0);
    };

    return (
        <div className='outer_container_thanks'>
            <div className='content_thanks'>
                <div className='binance_container'>
                    <img src={unit} alt="Binance Logo" className="binance_logo" />
                </div>
                <div className='coin_display'>
                    <img src="https://bearlock.xyz/media/coin_logo2.png"/>
                    <div className='coin_amount'>{formatNumber(coins)}</div>
                </div>
                <div className='message'>
                    The unit has started working for you
                </div>
            </div>
           
            <button className='button_thanks' onClick={handleClick}>
                Thank you {unitName} 💜
            </button>
        </div>
    );
}

export default ThanksNiki;
