import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./telegramgametest.scss";
import LoadingGameImg from "./Loadinggameimg.png";
import ReturnButtonImg from "./returnButton.png";

const TelegramGameTest = () => {
  const navigate = useNavigate();
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);

  const handleReturn = () => {
    navigate("/"); // Navigiere zur Hauptseite
  };

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Deaktivieren von Scroll- und Touch-Gesten
    const disableScroll = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    document.body.style.overflow = "hidden"; // Verhindert Scrollen auf der gesamten Seite
    window.addEventListener("touchmove", disableScroll, { passive: false });

    return () => {
      document.body.style.overflow = ""; // Scrollen wieder aktivieren, wenn das Component entfernt wird
      window.removeEventListener("touchmove", disableScroll);
    };
  }, []);

  return (
    <div className="telegram-game-container">
      {!isLandscape ? (
        <div className="orientation-overlay">
          <img
            src={LoadingGameImg}
            alt="Rotate Device"
            className="orientation-icon"
          />
        </div>
      ) : (
        <iframe
          src="https://bft.battleforcrypto.com/bft_game_telegram_test/"
          title="BFT Game Test"
          className="google-iframe"
        ></iframe>
      )}
      <button className="return-button" onClick={handleReturn}>
        <img
          src={ReturnButtonImg}
          alt="Return"
          className="return-button-icon"
        />
      </button>
    </div>
  );
};

export default TelegramGameTest;
