import React from 'react';
import calender from './calender.png';
import expend from './expend.png';
import coin from '../../pages/dollar_coin.png';

function DailyReword({ task, coins, icon, status, onClick }) {

    return (
        <div className="tasks_con" onClick={onClick}>
            <div className='additionalWrap'>
                <div className="iconWrapper ">
                    <img src={calender} className='img_friends' alt="Task Icon" />
                </div>
                <div className="infoWrapper">
                    <h2 className="name">Daily reward</h2>
                    <span className="coins_reward">+{(6649000).toLocaleString('en-US')} <img src="https://bearlock.xyz/media/coin_logo.png" className="coin_icon_task" alt="Coin-icon" /></span>
                </div>
            </div>
            <img src={expend} className='img_open' alt="open arrow" />
        </div>
    );
}

export default DailyReword;
