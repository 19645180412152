import React, { useEffect, useRef, useState } from 'react';
import { useAppData } from '../AppContext';
import "./home.scss";
import dollar_coin from "./dollar_coin.png";
import Footer from '../Components/Footer/Footer';
import energy_img from "./enegry.png";
import { useNavigate } from 'react-router-dom';
import { sendCoinsAndEnergy } from '../http';
import rocket from "./rocket.png"
import Block from './block';
import ThanksNiki from '../Components/ThanksNiki/ThanksNiki';
import dailyReward from "./dailyReward.png"
import dailyCypher from "./dailyCypher.png"
import dailyVidz from "./dailyVidz.png"
import dailyGame from "./dailyGame.png"
import buyskin from "./BuySkin.png"
import welcome from "./Welcome.png"
import settings from "./settings.png"
import WalletConnect from '../Components/WalletConnect/WalletConnect';
import Instruction from '../Components/Instruction/Instruction';
import { formatNumber } from '../Utils/formatNumber';
export const Home = () => {
    const overflow = 100
    document.body.style.overflowY = 'hidden'
    document.body.style.marginTop = `${overflow}px`
    document.body.style.height = window.innerHeight + overflow + "px"
    document.body.style.paddingBottom = `${overflow}px`
    window.scrollTo(0, overflow)
    
    
    const navigate = useNavigate();
    
    const {username,fisrtTime,setNotification,notification,passiveIncome, unit,passiveIncomePerHour,skin,playerLeague, coins, energy, energyLimit, setEnergy, clickCost, specialMode, total_coins_earned, handleEditCoinsClick, energyRechargeDelay, getUserInfoData } = useAppData();
    const { incrementClickCount} = useAppData();

    const [coinsHome, setCoinsHome] = useState(coins);
    const [prevCoins, setPrevCoins] = useState(coins);
    const [energyHome, setEnergyHome] = useState(energy);
    const [prevEnergy, setPrevEnergy] = useState(energy);
    const [lastClickTime, setLastClickTime] = useState(0);

    const coinsHomeRef = useRef(coinsHome);
    const prevCoinsRef = useRef(prevCoins);
    const energyHomeRef = useRef(energyHome);
    const prevEnergyRef = useRef(prevEnergy);

    const specialBackground = useRef(null);
    const coinImage = useRef(null);
    const leagueBar = useRef(null);
    const playZone = useRef(null);
    
    const blocksData=[
        {
        "name": "Daily reward",
        "link": "/earn",
        "image": dailyReward
        },
        {
        "name": "Daily puzzle",
        "link": "/puzzle",
        "image": dailyCypher
        },
        {
        "name": "Daily vidz",
        "link": "/vidz",
        "image": dailyVidz
        },
        {
        "name": "Mini Game",
        "link": "/mini",
        "image": dailyGame
        }
    ]

    useEffect(() => {
        const isFirstTime = localStorage.getItem('isFirstTime');
        if (!isFirstTime) {
            getUserInfoData();
            localStorage.setItem('isFirstTime', 'false');
        }
    }, []);

    useEffect(() => {
        coinsHomeRef.current = coinsHome;
        prevCoinsRef.current = prevCoins;
        energyHomeRef.current = energyHome;
        prevEnergyRef.current = prevEnergy;
    }, [coinsHome, prevCoins, energyHome, prevEnergy]);


    const handleTouchStart = (event) => {
        event.preventDefault();
        Array.from(event.touches).forEach(touch => {
            earnCoins(clickCost, touch.clientX, touch.clientY);
            HapticFeedback()
        });
    };

    function earnCoins(income, clientX, clientY) {
        incrementClickCount()
        if (specialMode) {
            let randomFactorOptions = [0.5, 1, 1.5];
            let randomFactor = randomFactorOptions[Math.floor(Math.random() * randomFactorOptions.length)];
            income += specialMode.bonus * randomFactor;
        } else {
            income = Math.min(income, energyHome);
        }

        if (income > 0) {
            handleEditCoinsClick(income);
            setCoinsHome(coinsHome + income);
            showIncome(income, clientX, clientY);
            if (!specialMode) {
                setEnergyHome(energy - income);
                setEnergy(energy - income);
            }
        }
    }

    const specialData = {
        "full_energy": {
            spanImage: `url(${energy_img})`,
            background: 'rgb(18 255 167 / 80%)'
        }
    };

    function showIncome(income, clientX, clientY) {
        const incomeElement = document.createElement('div');
        incomeElement.innerText = '+' + income;
        incomeElement.classList.add('income');
        incomeElement.style.left = `${clientX}px`;
        incomeElement.style.top = `${clientY}px`;
        document.body.appendChild(incomeElement);

        setTimeout(() => {
            incomeElement.style.top = `${clientY - 125}px`;
        }, 10);

        setTimeout(() => {
            incomeElement.style.opacity = "0";
        }, 250);

        setTimeout(() => {
            incomeElement.remove();
        }, 1000);
    }

    useEffect(() => {
        if (specialMode) {
            if (specialMode.mode === "full_energy") {
                setEnergyHome(energyLimit);
            }
            const specialInfo = specialData[specialMode.mode];
            specialBackground.current.style.display = 'block';
            specialBackground.current.style.opacity = 1;
            const specialBackgroundElement = specialBackground.current.querySelector('.special-background');
            const specialSpans = specialBackground.current.querySelectorAll('.special-background span');
            specialBackgroundElement.style.background = `radial-gradient(circle at center bottom, ${specialInfo.background}, rgb(12, 12, 12) 75%)`;
            specialSpans.forEach(span => {
                span.style.backgroundImage = specialInfo.spanImage;
            });
        } else {
            specialBackground.current.style.opacity = 0;
            specialBackground.current.addEventListener('transitionend', function () {
                specialBackground.current.style.display = 'none';
            }, { once: true });
        }
    }, [specialMode]);

    const [clickTimes, setClickTimes] = useState([]);

    const handleInteraction = (event) => {
        event.preventDefault();
        
        const currentTime = Date.now();
        const newClickTimes = clickTimes.filter(t => currentTime - t < 10000);
        newClickTimes.push(currentTime);
        setClickTimes(newClickTimes);

        if (newClickTimes.length > 200) {
            window.Telegram.WebApp.close();
            return;
        }

        setLastClickTime(currentTime);

        playZone.current.classList.add('animate');

        let clientX, clientY;
        if (event.type === 'touchstart') {
            Array.from(event.touches).forEach(touch => {
                clientX = touch.clientX;
                clientY = touch.clientY;
                earnCoins(clickCost, clientX, clientY);
            });
        } else if (event.type === 'mousedown') {
            clientX = event.clientX;
            clientY = event.clientY;
            earnCoins(clickCost, clientX, clientY);
        }

        setTimeout(() => {
            playZone.current.classList.remove('animate');
        }, 300);

       

    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setClickTimes([]);
        }, 2000);

        return () => clearInterval(intervalId);
    }, []);

      useEffect(() => {
        const energyUpdateInterval = setInterval(() => {
            setEnergyHome((prevEnergy) => {
                if (prevEnergy < energyLimit) {
                    return Math.min(prevEnergy + energyRechargeDelay, energyLimit);
                }
                return prevEnergy;
            });
            setEnergy((prevEnergy) => {
                if (prevEnergy < energyLimit) {
                    return Math.min(prevEnergy + energyRechargeDelay, energyLimit);
                }
                return prevEnergy;
            });
        }, 1000);
        return () => clearInterval(energyUpdateInterval);
    }, [energyLimit, energyRechargeDelay]);

    useEffect(() => {    
    }, [total_coins_earned]);

    useEffect(() => {
        if (leagueBar.current && playerLeague) {
            const coin_limit = playerLeague.coin_limit === "max" ? total_coins_earned : playerLeague.league_coins;
            const width = coin_limit === "max" ? 100 : (coins / playerLeague.league_coins) * 100;
            if(width < 1 ){
                leagueBar.current.style.width = `${Math.min(1, 100)}%`;
            }
            else{
                leagueBar.current.style.width = `${Math.min(width, 100)}%`;

            }
        }
    }, [total_coins_earned,playerLeague]);
  
    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            if (window.Telegram.WebApp.BackButton && window.Telegram.WebApp.BackButton.isVisible) {
                window.Telegram.WebApp.BackButton.hide();
            }
        }
    }, []);
    const HapticFeedback = () => {
        if (window.Telegram?.WebApp?.HapticFeedback) {
          window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
        }
      };
    return (
        <>
        
        {fisrtTime && <Instruction/>}
        <div className='homeMain'>
            {passiveIncome > 0 && <ThanksNiki coins={passiveIncome} />}
            {passiveIncome === 0 && Object.keys(notification).length > 0 && 
                <WalletConnect
                    description={notification.description}
                    title={notification.title}
                    wallet_address={''}
                    avaliable={true}
                    onClick={()=>setNotification("")}
                />
            }

            <div className='header_home'>
                <div className='skins' onClick={()=>navigate("/skins")}>
                    <div className='username_container'>
                        {username}
                    </div>
                    
                    <div className='buycontainer'>
                        <img src={buyskin}/>
                        <div className='buy_text'>Buy <br/> skin </div>
                    </div>
                    
                </div>
                <div className='flex_end'>
                    
                    <div className='league_home'onClick={()=>navigate("/leagues")}>
                        <div className='flex_text'>
                            <div>{playerLeague.name}</div>
                            <div>{playerLeague.position}</div>
                        </div>
                        <div className="leagueBarHome">
                            <div className="energyBarHome" ref={leagueBar} />
                        </div>
                    </div> 

                    <div className='profit_container'>
                        <div>
                            <img src={unit} onClick={()=>navigate("/units")}/>
                        </div>
                       
                        <div>
                            <div className='profit_text'>Profit per hour</div>
                            <div className='profitInfo'> <img src="https://bearlock.xyz/media/coin_logo.png"/>+{formatNumber(passiveIncomePerHour) }</div>
                        </div>
                        <img src={settings}/>
                    </div>
                </div>
                

            </div>

            <div className="home">
            
            <div className='dailyBlock'>
                 {blocksData.map((block, index) => (
                <Block
                    key={index}
                    name={block.name}
                    link={block.link}
                    image={block.image}
                />
            ))}
            </div>
           
            <div className="effect-background" ref={specialBackground}>
                <div className="special-background">
                    <span style={{ '--i': 16 }}></span>
                    <span style={{ '--i': 11 }}></span>
                    <span style={{ '--i': 19 }}></span>
                    <span style={{ '--i': 13 }}></span>
                    <span style={{ '--i': 15 }}></span>
                    <span style={{ '--i': 20 }}></span>
                    <span style={{ '--i': 12 }}></span>
                    <span style={{ '--i': 17 }}></span>
                </div>
            </div>

                <div className='column'>
                    <div className='row_home'>
                        <div className="stats">
                            <img src="https://bearlock.xyz/media/coin_logo.png" className="stats-coin" alt="Coin icon" />
                            <div id="balance" className="coin-count">{coins.toFixed(0).toLocaleString('en-US')}</div>
                        </div>
                    </div>

                    <div className='leagueContainer' onClick={() => { navigate('/leagues'); }}>
                    </div>
                    </div>

                    <div id="playZone" className="play-zone" 
                            ref={playZone} 
                            onTouchStart={handleTouchStart}
                            onPointerDown={handleInteraction}
                        >
                        <div className="inner-circle" ref={coinImage}>
                            <img
                                src={skin}
                                alt="Punch"
                                draggable="false"
                            />
                        </div>
                    </div>
                    <div className="energy-wrapper">
                    <div className='row_home'>
                        <img src={energy_img} className="iconHome" alt="Energy icon" />
                        <div className="energy-counter">
                           {energy}/{energyLimit}
                        </div>
                    </div>
                        <div className='row_home' onClick={() => { navigate('/boosts'); }}>
                            <img className="iconHome" src={rocket}></img>
                            <div className="energy-counter">Booster</div>

                        </div>
                    </div>
            <Footer />

        </div>

        </div>
        </>
        
       
    );
};
