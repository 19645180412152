import { RouterProvider, createBrowserRouter, Link } from 'react-router-dom'
import './App.css';
import React, { useEffect } from 'react';
import { AppProvider } from './AppContext';
import { Home } from './pages/Home';
import { Frens } from './Components/Friends/Frens';
import { Games } from './Components/Games/Games';
import { Tasks } from './Components/Tasks/Tasks';
import { TeamExplorer } from './Components/Team/TeamExplorer';
import { Team } from './Components/Team/Team';
import PetNaming from './Components/Pet/PetNaming/PetNaming';
import MainPet from './Components/Pet/MainPet/MainPet';
import { Boost } from './Components/Boost/Boost';
import Map from './Components/Map/Map';
import PetCard from './Components/Pet/MainPet/PetCard/PetCard';
import BuyCoins from './Components/BuyCoins/BuyCoins';
import { InviteFriend } from './Components/Friends/InviteFriend';
import Settings from './Components/Settings/Settings';
import LenguaguePicker from './Components/Settings/LenguaguePicker';
import Loading from './Components/Loading/Loading';
import ChoosePet from './Components/ChoosePet/ChoosePet';
import Mine from './Components/Mines/Mine';
import Telegramgametest from './Components/Telegramgametest/Telegramgametest';
import Airdrop from './Components/Airdrop/Airdrop';
import { Leagues } from './Components/Leagues/Leagues';
import { LeagueProvider } from './Components/StatsMain/LeagueContext';
import Skins from './Components/Skins/Skins';
import Vidz from './Components/Tasks/Vidz';
import ComingSoon from './Components/ComingSoon/ComingSoon';
import Instruction from './Components/Instruction/Instruction';
import Units from './Components/Units/Units';
import WalletConnect from './Components/WalletConnect/WalletConnect';
import Puzzle from './Components/Puzzle/Puzzle';
const router = createBrowserRouter([
  {
    path: '/telegramgametest',
    element: <Telegramgametest />
  },
  {
    path: '/mine',
    element: <Mine />
  },
  {
    path: '/loading',
    element: <Loading />
  },
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/leagues',
    element: <Leagues />
  },
  {
    path: '/frens',
    element: <Frens />
  },
  {
    path: '/games',
    element: <Games />
  },
  {
    path: '/earn',
    element: <Tasks />
  },
  {
    path: '/team-explorer',
    element: <TeamExplorer />
  },
  {
    path: '/team/:teamId',
    element: <Team />
  },
 
  {
    path: '/choose_pet',
    element: <ChoosePet />
  },
  {
    path: '/PetNaming/:PetId',
    element: <PetNaming />
    },
  {
    path: '/MainPet',
    element: <MainPet />
  },
  {
    path: '/PetCard',
    element: <PetCard />
  },
  {
    path: '/boosts',
    element: <Boost />
  },
  {
    path: '/buy',
    element: <BuyCoins />
  },
  {
    path: '/map',
    element: <Map />
  },
  {
    path: '/friendsList',
    element: <InviteFriend />
  },
  {
    path: '/settings',
    element: <Settings />
  },
  {
    path: '/lenguague_picker',
    element: <LenguaguePicker />
  },
  {
    path: '/airdrop',
    element: <Airdrop />
  },
  {
    path: '/skins',
    element: <Skins />
  },
  {
    path: '/vidz',
    element: <Vidz />
  },
  {
    path: '/coming',
    element: <ComingSoon />
  },
  {
    path: '/instruction',
    element: <Instruction />
  },
  {
    path: '/units',
    element: <Units />
  },
  {
    path: '/wallet',
    element: <WalletConnect />
  },
  {
    path: '/puzzle',
    element: <Puzzle />
  },
  {
    path: '/mini',
    element: <Puzzle />
  }
]);

function App() {

  return (
    <AppProvider>
      <LeagueProvider>
        <RouterProvider router={router} />
      </LeagueProvider>
    </AppProvider>
  );
}

export default App;
