const URL = "https://bearlock.xyz/"

// export let USER_ID = "5928954497";

export let USER_ID = null 
const hash = window.Telegram.WebApp.initData;
// const hash = "test";

if (window.Telegram && window.Telegram.WebApp) {
  window.Telegram.WebApp.ready();

  if (window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
    USER_ID = window.Telegram.WebApp.initDataUnsafe.user.id;
  } else {
    console.error('Telegram user data is not available.');
  }
} 
else {
  console.error('Telegram WebApp is not initialized.');
}


const fetchData = async (request) => {
  try {
    const response = await fetch(`${URL + request}`, {
      method: "get",
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420" ,
        "Authorization": `Bearer ${hash}`
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log('data:', request, data);
    return data;
  } catch (error) {
    return null;
  }
};

const sendData = async (request, data) => {
  try {
    const formattedRequest = request.endsWith('/') ? request : `${request}/`;
    const formData = new URLSearchParams(data).toString();
    const response = await fetch(`${URL}${formattedRequest}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${hash}`

      },
      body: formData,
    });
    const result = await response.json();
    console.log('post:', request, result);
    return result;
  } catch (error) {
    console.error('Error fetching:', error);
    return null;
  }
};

export const sendCoinsAndEnergy = async (coins) => {
  const response = await sendData("update_coins_and_energy", { clicks_count: coins,});
  return response;
};
export const completeInstruction = async () => {
  const response = await sendData("complete_instruction", {user_id: USER_ID});
  return response;
};
export const getPlayerExtraTasks = async () => {
  const response = await fetchData("get_player_extra_tasks"  + "?user_id=" + USER_ID);
  return response;
};

export const sendUpgrade = async (upgradeId) => {
  const response = await sendData("buy_upgrade", {user_id: USER_ID, upgrade: upgradeId});
  return response;
};

export const sendSpecial = async (specialId) => {
  const response = await sendData("use_boost", {user_id: USER_ID, boost: specialId});
  return response;
};

export const updatePlayerWalletAddress = async (wallet_address) => {
  const response = await sendData("update_player_wallet_address", {user_id: USER_ID, wallet_address: wallet_address});
  return response;
};
export const sendJoinTeam = async (teamId) => {
  const response = await sendData("join_team", {user_id: USER_ID, team_id: teamId});
  return response;
};
export const seeTask = async (task_id) => {
  const response = await sendData("see_task", { task_id: task_id });
  return response;
};

export const sendLeaveTeam = async (teamId) => {
  const response = await sendData("leave_team", {user_id: USER_ID, team_id: teamId});
  return response;
};
export const buyCoinSkin = async (coin_skin_id) => {
  const response = await sendData("buy_coin_skin", {user_id: USER_ID, coin_skin_id: coin_skin_id});
  return response;
};
export const selectCoinSkin = async (coin_skin_id) => {
  const response = await sendData("select_coin_skin", {user_id: USER_ID, coin_skin_id: coin_skin_id});
  return response;
};
export const sendGame = async () => {
  const response = await sendData("send_games_message", {user_id: USER_ID});
  return response;
};

export const buyPet = async (pet_id, name) => {
  const response = await sendData("buy_pet", {user_id: USER_ID, pet_id: pet_id, name: name});
  return response;
};



export const feedPet = async () => {
  const response = await sendData("feed_pet", {user_id: USER_ID});
  return response;
};

export const buyFood = async (food_id) => {
  const response = await sendData("buy_food", {user_id: USER_ID, food_id:food_id});
  return response;
};

export const manageMeme = async (meme_id) => {
  const response = await sendData("manage_meme", {user_id: USER_ID, meme_id: meme_id});
  return response;
};

export const selectFood = async (food_id) => {
  const response = await sendData("select_food", {user_id: USER_ID, food_id:food_id});
  return response;
};
export const selectUnit = async (unit_id) => {
  const response = await sendData("select_unit", {user_id: USER_ID, unit_id:unit_id});
  return response;
};
export const SendInvite = async () => {
  const response = await sendData("send_invite_message", {user_id: USER_ID});
  return response;
};
export const completeTask = async (task_id) => {
  const response = await sendData("complete_task", {task_id:task_id});
  return response;
};
export const GeneratePaymentLink = async (amount, product) => {
  const response = await sendData("generate_payment_link", {user_id: USER_ID, amount:amount,product:product });
  return response;
};

export const completeFriendsTask = async () => {
  const response = await sendData("complete_friends_task", {user_id: USER_ID});  
  return response;
};

export const claimDailyRewords = async () => {
  const response = await sendData("complete_daily_task", {user_id: USER_ID});  
  return response;
};

// 1234567890

export const getUserInfo = async () => {
  const response = await fetchData("initialize_user" + "?user_id=" + USER_ID);  
  return response;
};

export const getUserDaylys = async () => {
  const response = await fetchData("get_user_daily_tasks" + "?user_id=" + USER_ID);  
  return response;
};

export const getUserMemes = async (category) => {
  const response = await fetchData("get_player_memes" + "?user_id=" + USER_ID+"&category="+category);  
  return response;
};

export const getUserUpgrades = async () => {
  const response = await fetchData("get_user_upgrades" + "?user_id=" + USER_ID)
  return response;
};

export const getUserSpecials = async () => {
  const response = await fetchData("get_user_boosts" + "?user_id=" + USER_ID)
  return response;
}

export const getUserTasks = async (category) => {
  const response = await fetchData("get_user_tasks" + "?user_id=" + USER_ID+"&category="+category)
  return response;
}

export const getUserRefferedCount = async () => {
  const response = await fetchData("friends_reffered_count" + "?user_id=" + USER_ID)
  return response;
}

export const getUserFriends = async () => {
  const response = await fetchData("friends_list" + "?user_id=" + USER_ID)
  return response;
}

export const getSearchTeams = async (searchQuery) => {
  const response = await fetchData(`get_teams?search_query=${searchQuery}`);
  return response;
};

export const getTeam = async (team_id) => {
  const response = await fetchData(`get_team?team_id=${team_id}`);
  return response;
};

export const getTopTeams = async () => {
  const response = await fetchData(`get_top5_teams`);
  return response;
};

export const getPlayerCoinSkins = async () => {
  const response = await fetchData(`get_player_coin_skins`+ "?user_id=" + USER_ID);
  return response;
};


export const getAllPets= async () => {
  const response = await fetchData("get_all_pets")
  return response;
}
export const getPet= async (petId) => {
  const response = await fetchData("get_pet" + "?pet_id=" + petId)
  return response;
}
export const getAllLeagues = async () => {
  const response = await fetchData("get_all_leagues"+"?user_id=" + USER_ID)
  return response;
}
export const getLeague = async (time_period,league) => {
  const response = await fetchData("get_league"+ "?time_period=" + time_period +"&league="+league)
  return response;
}
export const getFriendsList = async () => {
  const response = await fetchData("friends_list"+ "?user_id=" + USER_ID)
  return response;
}

export const getInviteLink = async () => {
  const response = await fetchData("get_invite_link"+ "?user_id=" + USER_ID)
  return response;
}

export const getPlayerUnits = async () => {
  const response = await fetchData("get_player_units"+ "?user_id=" + USER_ID)
  return response;
}
